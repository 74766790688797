<!--
 * @Author: zoujp
 * @Date: 2020-11-10 08:56:32
 * @LastEditTime: 2020-11-10 15:13:34
 * @LastEditors: Please set LastEditors
 * @Description: 版本记录
 * @FilePath: \user\src\views\plan\detail\components\versionRecord.vue
-->
<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    title="版本记录"
    width="800px"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <div class="detail-wrap" v-en-loading="enloading">
      <div class="mod" v-for="item in dataList" :key="item.id">
        <div class="tit">
          <div class="name">{{item.version}}</div>
          <div class="time">{{item.createTime}}</div>
        </div>
        <div class="content">{{item.content}}</div>
      </div>
    </div>
  </en-dialog>
</template>

<script>
import { request } from "en-js";
import { planDetailService } from "@/api";

export default {
  name: "VersionRecord",
  components: { },
  props: {
    schemeId: {
      type: String
    }
  },
  data() {
    return {
      enloading: false,
      visiable: false,
      dataList: []
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getList();
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * @description: 获取版本记录
     * @return {*}
     */
    @request(true, "enloading")
    async getList() {
      const pa = {
        schemeId: this.schemeId,
        pageNo: 1,
        pageSize: 99999

      };
      console.log(pa);
      const res = await planDetailService.querySchemeApproveHistory(pa);
      this.dataList = res.records || [];
    },
    closeFn() {
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .detail-wrap{
    overflow-y: auto;
    height: 500px;
    .mod{
      padding: 15px;
      margin-bottom: 4px;
      border-radius: 4px;
      border: 1px solid #E8ECF2;
      background: #F5F8FC;
      .tit{
        display: flex;
        justify-content: space-between;
        .name{
          font-weight: bold;
          font-size: 12px;
          color: #333333;
        }
        .time{
          font-size: 12px;
          color: #636C78;
        }
      }
      .content{
        color:#636C78;
        font-size: 12px;
        padding-top: 5px;
      }
    }
  }
</style>
