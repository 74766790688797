var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "en-dialog",
        {
          staticClass: "com-log-wrap",
          attrs: {
            visible: _vm.visiable,
            title: "对比日志",
            width: "800px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeFn,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.enloading,
                  expression: "enloading",
                },
              ],
              staticClass: "compare-wrap",
            },
            [
              _c(
                "en-table",
                { attrs: { data: _vm.dataList, height: "400px" } },
                [
                  _c("en-table-column", {
                    attrs: { width: "50", type: "index" },
                  }),
                  _c("en-table-column", {
                    attrs: {
                      prop: "templateName",
                      label: "模板名称",
                      width: "180",
                    },
                  }),
                  _c("en-table-column", {
                    attrs: {
                      prop: "templateType",
                      label: "模板类型",
                      width: "20%",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.mapType[row.templateType]) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2024455957
                    ),
                  }),
                  _c("en-table-column", {
                    attrs: { prop: "comparisonLog", label: "对比日志" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }