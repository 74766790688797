<!--
 * @Author: zoujp
 * @Date: 2020-11-04 16:08:18
 * @LastEditTime: 2020-11-05 15:07:54
 * @LastEditors: Please set LastEditors
 * @Description: 我的方案
 * @FilePath: \user\src\views\plan\myPlan\index.vue
-->

<template>
  <div class="myplan" v-en-loading="enloading">
    <en-title-card name="我的方案">
    </en-title-card>
    <div class="myplan-cent">
      <dl class="col-mod" v-for="item in tablelist" :key="item.id">
        <dt class="col-logo">
          <en-user-logo
            user-name=""
            width="50"
            height="50"
            :image-url="item.logoUrl"
          ></en-user-logo>
        </dt>
        <dd class="col-name">{{ item.name }}</dd>
        <dd class="col-state" :style="{color:planStatus[item.status].color}">{{item.version}}  {{planStatus[item.status].name}}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { request } from "en-js";
import { salesTrendsService } from "@/api";

export default {
  name: "myPlan",
  components: { },
  data() {
    return {
      enloading: false,
      tablelist: [],
      // 3.5 方案状态
      planStatus: {
        1: { color: "#4fd2c2", name: "准备提交" },
        2: { color: "#fd9a4a", name: "审核中" },
        3: { color: "#f35d5e", name: "被拒绝" },
        4: { color: "#f35d5e", name: "已撤销" },
        5: { color: "#fd9a4a", name: "销售中" },
        6: { color: "#606060", name: "已下架" },
        7: { color: "#606060", name: "强制下架" }
      }
    };
  },
  mounted() {
    this.querySchemeList();
  },
  methods: {
    /**
     * @description: 查询方案
     */
    @request(true, "enloading")
    async querySchemeList() {
      const pa = {
        pageNo: 1, pageSize: 100000000
      };
      const rsp = await salesTrendsService.querySchemeList(pa);
      const arr = rsp.records || [];
      console.log(arr);
      this.tablelist = arr;
    }
  }
};
</script>
<style lang="scss" scoped>
  .myplan{
    .myplan-cent{
      height: calc(100% - 50px);
      overflow-y: auto;
      width: 100%;
      background-color: #ffffff;
      padding: 10px 20px;
      .col-mod{
        width: 275px;
        height: 100px;
        font-size: 12px;
        padding-top: 20px;
        margin-bottom: 20px;
        margin-right: 20px;
        border: 1px solid #eee;
        border-radius: 4px;
        background: #fff;
        position: relative;
        display: inline-block;
        cursor: pointer;
        vertical-align: top;
        .col-logo{
          position: absolute;
          top: 25px;
          left: 10px;
          img{
            display: block;
            border-radius: 50%;
            width:50px;
            height: 50px;
          }
        }
        .col-name{
          color: #111;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          margin-top: 10px;
          margin-left: 70px;
          margin-right: 10px;
        }
        .col-state{
          margin-left: 70px;
          margin-top: 8px;
        }
      }
    }
  }
</style>
