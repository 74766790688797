var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.enloading,
          expression: "enloading",
        },
      ],
      staticClass: "plan-login-list",
    },
    [
      _c("en-title-card", [
        _c(
          "div",
          { staticClass: "header-top", attrs: { slot: "left" }, slot: "left" },
          [
            _c("en-icon", {
              staticClass: "back-icon",
              attrs: { name: "fanhui", size: "16px" },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$router.go(-1)
                },
              },
            }),
            _c("span", [_vm._v("已安装方案")]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "login-wrap" },
        [
          _c(
            "en-table",
            {
              attrs: {
                data: _vm.tableData,
                "height-config": { bottomHeight: 15 },
                "page-config": {
                  pageModel: _vm.pageModel,
                  changeMethod: _vm.handlePageChanged,
                },
              },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("en-table-column", { attrs: { type: "index", width: "60" } }),
              _c("en-table-column", {
                attrs: { title: "方案名称", field: "name" },
              }),
              _c("en-table-column", {
                attrs: { title: "方案版本", field: "version" },
              }),
              _c("en-table-column", {
                attrs: { title: "安装版本", field: "installVersion" },
              }),
              _c("en-table-column", {
                attrs: { title: "安装时间", field: "createTime" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }