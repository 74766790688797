<!--
 * @Author: zhulin
 * @Date: 2020-08-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-20 14:58:21
 * @Description: 筛选
 * @FilePath: \user\src\views\plan\salesTrends\components\screenList.vue
-->
<template>
  <en-dialog title="销售明细" :visible.sync="show" en-body-scroll @close="closeDialog"  width="800px">
    <div class="list-alert">
    <en-table :data="schemeList">
      <en-table-column prop="schemeName" label="方案名称"> </en-table-column>
      <en-table-column prop="createTime" label="销售日期"> </en-table-column>
      <en-table-column prop="totals" width="100px" label="销售数量"> </en-table-column>
    </en-table>
    </div>
  </en-dialog>
</template>

<script>
import { salesTrendsService } from "@/api";

export default {
  props: {
    show: {
      type: Boolean
    },
    listData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      schemeList: [],
      pa: {}
    };
  },
  mounted() {
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.pa = this.listData || [];
        this.queryRefCorpDetail();
      }
    }
  },
  methods: {
    async queryRefCorpDetail() {
      const rsp = await salesTrendsService.queryRefCorpDetail(this.pa);
      this.schemeList = rsp || [];
    },
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toclose", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.list-alert{
  padding-bottom:40px;
  min-height:300px;
}
</style>
