var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "en-dialog",
        {
          staticClass: "plan-install-auth",
          attrs: {
            visible: _vm.visiable,
            title: "验证管理员身份",
            width: "700px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeFn,
          },
        },
        [
          _c("div", { staticClass: "form-wrap" }, [
            _c(
              "div",
              { staticClass: "mod" },
              [
                _c("el-form", { ref: "myForm", attrs: { model: _vm.form } }, [
                  _c(
                    "div",
                    { staticClass: "account form-item" },
                    [
                      _c("div", { staticClass: "form-lable" }, [
                        _vm._v("账号"),
                      ]),
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入管理员账号",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "account", $$v)
                          },
                          expression: "form.account",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "password form-item" },
                    [
                      _c("div", { staticClass: "form-lable" }, [
                        _vm._v("密码"),
                      ]),
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "请输入密码",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("div", { staticClass: "footer-tip" }, [
                _vm._v("注：需要验证管理员身份才可以安装"),
              ]),
              _c(
                "en-button",
                {
                  staticClass: "save-btn",
                  attrs: { backgroundColor: "#3E90FE" },
                  on: { click: _vm.save },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }