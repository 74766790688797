<!--
 * @Author: zoujp
 * @Date: 2020-11-06 15:45:25
 * @LastEditTime: 2024-02-29 19:25:00
 * @LastEditors: Please set LastEditors
 * @Description: 方案详情
 * @FilePath: \user\src\views\plan\detail\index.vue
-->

<template>
  <div class="plan-detail" v-en-loading="enloading">
    <div class="list-title">
        <div class="title-desc" @click="back">
          <en-icon name="iconfanhuishangcengji" size="small" color="#A9B5C6" class="icon-cls"> </en-icon>
          <span class="title">方案详情</span>
        </div>
        <div class="title-btn">
          <en-button icon="rizhi" iconColor="#A9B5C6" iconSize="16" color="#333333" text @click="versionRecord">
            版本记录
          </en-button>
        </div>
    </div>
    <div class="detail-head">
      <div class="inner-head">
       <en-user-logo
          user-name=""
          :image-url="resMap.logoUrl"
          size="80"
        ></en-user-logo>
      </div>
      <div class="inner-desc">
        <div class="name">{{resMap.name}}</div>
        <div class="info">提供商：{{resMap.corpName}} 专家 {{resMap.nameToo}} ¥ {{resMap.priceType}}</div>
      </div>
      <div class="inner-btn">
         <en-button v-if="resMap.installFlag===0" type="white" borderColor="#3e90fe" color="#3e90fe">已安装</en-button>
         <en-button v-else type="primary" @click="install">安装</en-button>
         <en-button type="primary" @click="exp" backgroundColor="#46d48a" class="tiyan" borderColor="#46d48a">体验</en-button>
      </div>
    </div>
    <div class="detail-use mod">
       <en-title-card name="方案使用模块">
         <div slot="left">
          <el-popover
            popper-class="plan-use-title-tip"
            placement="bottom-start"
            title=""
            width="480"
            trigger="hover"
            >
            <div class="use-tip-content">
                <div class="cent">
                  <div class="dot">
                    <div class="dot-in"></div>
                  </div>
                  <div class="msg">建模模块：模块的安装不影响安装此方案企业的配置</div>
                </div>
                <div class="cent">
                  <div class="dot">
                    <div class="dot-in"></div>
                  </div>
                  <div class="msg">其他模块：模块的安装将会影响方案本身的配置以及方案审核的结果，请慎重选择</div>
                </div>
            </div>
            <en-icon
              slot="reference"
              name="tishi"
              size="16"
              color="#F7BF27"
              >
            </en-icon>
          </el-popover>
        </div>
       </en-title-card>
       <div class="use-list">
         <div class="use-item">
           <div class="use-tit">建模模块</div>
           <div class="use-cent" v-for="item in getUseModel(1)" :key="item.id">
              <div class="chk-wrap">
                  <div class="box-in">
                    <el-checkbox v-model="checked" disabled></el-checkbox>
                  </div>
              </div>
              <div class="cent-wrap">
                <div class="cent-tit">
                  <div class="cent-head">
                    <div class="head-wrap">
                      <div class="icon-in">
                        <en-icon :name="'icon-pnan-'+item.icon" size="20" color="#fff"></en-icon>
                      </div>
                    </div>
                    <div class="content-wrap">
                      <div class="tit">{{item.name}}</div>
                      <div class="info">{{item.description}}</div>
                    </div>
                    <div class="arrow-wrap" @click="expand(item)">
                      <en-icon :name="item.isShow?'zhankai1':'shouqi'" size="14" color="#75AADC"></en-icon>
                    </div>
                  </div>
                </div>
                <div class="mod-list" v-show="item.isShow">
                  <div class="class" v-for="cls in item.detailList" :key="cls.id">
                    <div class="class-name">
                      <el-checkbox v-model="checked" disabled>{{cls.name}}</el-checkbox>
                    </div>
                    <div class="item">
                        <div class="items" v-for="mod in cls.detailList" :key="mod.id"><el-checkbox v-model="checked" disabled>{{mod.name}}</el-checkbox></div>
                    </div>
                  </div>
                </div>
              </div>
           </div>
         </div>
         <div class="use-item">
           <div class="use-tit">其他模块</div>
           <div class="use-cent" v-for="item in getUseModel(2)" :key="item.id">
              <div class="chk-wrap">
                  <div class="box-in">
                    <el-checkbox v-model="checked" disabled></el-checkbox>
                  </div>
              </div>
              <div class="cent-wrap">
                <div class="cent-tit">
                  <div class="cent-head">
                    <div class="head-wrap">
                      <div class="icon-in">
                        <en-icon :name="'icon-pnan-'+item.icon" size="20" color="#fff"></en-icon>
                      </div>
                    </div>
                    <div class="content-wrap">
                      <div class="tit">{{item.name}}</div>
                      <div class="info">{{item.description}}</div>
                    </div>
                    <div class="arrow-wrap" @click="expand(item)">
                      <en-icon :name="item.isShow?'zhankai1':'shouqi'" size="14" color="#75AADC"></en-icon>
                    </div>
                  </div>
                </div>
                <div class="mod-list" v-show="item.isShow">
                  <div class="class" v-for="cls in item.detailList" :key="cls.id">
                    <div class="class-name">
                      <el-checkbox v-model="checked" disabled>{{cls.name}}</el-checkbox>
                    </div>
                    <div class="item">
                        <div class="items" v-for="mod in cls.detailList" :key="mod.id"><el-checkbox v-model="checked" disabled>{{mod.name}}</el-checkbox></div>
                    </div>
                  </div>
                </div>
              </div>
           </div>
         </div>
       </div>
    </div>
    <div class="detail-data mod">
       <en-title-card name="方案库数据">
        <div slot="right">
            <en-button icon="rizhi" text iconSize="16" color="#333333" iconColor="#A9B5C6" @click="compareRecord">
              对比日志
            </en-button>
        </div>
      </en-title-card>
      <div class="data-list">
        <div class="data-tit">基础数据</div>
        <div class="data-cent">
          <en-card v-for="item in resMap.baseDataList" :key="item.id"
            class="data-mod"
            type='normal'
            :title='item.name'
            :sub-title='getSubTitle(item)'
            icon-name='mingxizhang-xianxing'
            icon-color='#ffffff'
            icon-bg='#3e90fe'
            edit-able='false'
            disabled='false'
            @click.native="popBaseField(item)"
          >
          </en-card>
        </div>
      </div>
       <div class="data-list">
        <div class="data-tit">数据字典</div>
        <div class="data-cent">
          <en-card v-for="item in resMap.dictionaryList" :key="item.id"
            class="data-mod"
            type='normal'
            :title='item.name'
            :sub-title='getSubTitle(item)'
            icon-name='mingxizhang-xianxing'
            icon-color='#ffffff'
            icon-bg='#3e90fe'
            edit-able='false'
            disabled='false'
            @click.native="popBaseField(item)"
          >
          </en-card>
        </div>
      </div>
    </div>
    <div class="detail-desc mod">
      <en-title-card name="方案库数据">
      </en-title-card>
      <div class="desc-list">
        <div class="desc-tit">
          <div class="version-wrap">
            <div class="version">版本：{{resMap.version}}</div>
            <div class="version-date">{{resMap.versionTime}}</div>
          </div>
          <div class="describe">{{resMap.contentDesc}}</div>
        </div>
        <div class="desc-content" v-html="resMap.descInfo"></div>
      </div>
    </div>
    <versionRecord  ref="verRecord" :schemeId="query.eschemeId"></versionRecord>
    <compareLog  ref="comLog" :schemeId="query.eschemeId" :schemeVersion="query.eversion"></compareLog>
    <installConfirm  ref="installCfm" @confirmSave="confirmSave"></installConfirm>
    <installAuth  ref="installAut" @authSave="authSave"></installAuth>
    <baseField  ref="bField" :schemeId="query.eschemeId" :schemeVersion="query.eversion" :fieldAttr="fieldAttr"></baseField>
  </div>
</template>

<script>

import { request } from "en-js";
import { planDetailService, planApplyService } from "@/api";
import Store from "@/store";
import versionRecord from "./components/versionRecord"; // 版本记录
import compareLog from "./components/compareLog"; // 对比日志
import baseField from "./components/baseField"; // 基础数据字段
import installConfirm from "../planApply/components/installConfirm"; // 过滤
import installAuth from "../planApply/components/installAuth";

// 过滤
export default {
  name: "planDetail",
  components: {
    versionRecord, compareLog, installConfirm, installAuth, baseField
  },
  data() {
    return {
      enloading: false,
      checked: true,
      query: { // 路由参数
        eid: this.$route.query.id,
        eversion: this.$route.query.version,
        eschemeId: this.$route.query.schemeId,
        etenantId: this.$route.query.tenantId,
        etype: this.$route.query.type
      },
      authParams: {},
      confirmParams: {},
      fieldAttr: {},
      resMap: [] // 详情数据
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    back() {
       this.$router.go(-1);
    },
    /**
     * @description: 模块展开收起
     * @item 当前数据集合
     * @return {*}
     */
    expand(item) {
      item.isShow = !item.isShow;
      this.$forceUpdate();
    },
    /**
     * @description: 获取详情数据
     * @return {*}
     */
    @request(true, "enloading")
    async getDetail() {
      const pa = {
        id: this.query.eid,
        version: this.query.eversion,
        schemeId: this.query.eschemeId
      };
      this.resMap = await planDetailService.querySchemeApproveDetail(pa);
      this.resMap.modules.forEach((itm) => {
        itm.isShow = false;
      });
      console.log(this.resMap);
    },
    /**
     * @description: 版本记录
     * @return {*}
     */
    versionRecord() {
      this.$refs.verRecord.visiable = true;
    },
    /**
     * @description: 对比日志
     * @return {*}
     */
    compareRecord() {
      this.$refs.comLog.visiable = true;
    },
    /**
     * @description: 体检
     * @return {*}
     */
    exp() {
      this.$router.push({ path: "/plan/loginList", query: { schemeVersionId: this.$route.query.schemeVersionId } });
    },
    /**
     * @description: 安装
     * @return {*}
     */
    async install() {
      const pa = {
        schemeId: this.eschemeId, version: this.eversion
      };
      console.log(pa);
      const res = this.asyncqueryAdminMulTenant();
      console.log(res);
      if (!res.length) { // 非本企业 授权
        this.installAuthFn(pa);
      } else if (res.length === 1) { // 本企业
        await this.installSchema(pa);
      } else { // 多企业
        pa.planTenantId = Store.getters.userInfo.tenantId;
        this.installConfirmFn(pa);
      }
    },
    /**
     * @description: 验证安装回调
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async authSave(pa) {
      const res = await planApplyService.checkAccountPassword(pa);
      const msg = { 1: "验证通过", 2: "账号或密码错误", 3: "账户已锁定，24小时后自动解锁" };
      if (res.result.toString() === "1") {
        this.$message({
          message: msg[res.result],
          type: "success"
        });
      } else {
        this.$message.error(msg[res.result]);
      }
      setTimeout(() => {
        if (res.result.toString() === "1") {
          this.installSchema(Object.assign(this.authParams, pa));
        }
      }, 1000);
    },
    /**
     * @description: 确认安装回调
     * @param {*}
     * @return {*}
     */
    confirmSave() {
      this.installSchema(this.confirmParams);
    },
    /**
     * @description: 确认安装
     * @param {*} pa
     * @return {*}
     */
    installConfirmFn(pa) {
      this.confirmParams = pa;
      this.$refs.installCfm.visiable = true;
    },
    /**
     * @description: 方案安装
     * @param {*} pa
     * @return {*}
     */
    async installSchema(pa) {
      await planApplyService.installScheme(pa);
      this.getDetail();
    },
    /**
     * @description: 授权安装
     * @param {*} pa
     * @return {*}
     */
    installAuthFn(pa) {
      this.authParams = pa;
      this.$refs.installAut.visiable = true;
    },
    /**
     * @description: 判断是否多企业
     * @param {*}
     * @return {*}
     */
    // @request(true, "enloading")
    async asyncqueryAdminMulTenant() {
      const pa = {
        mobileNo: Store.getters.userInfo.mobileNo
      };
      const rsp = await planApplyService.queryAdminMulTenant(pa);
      return rsp;
    },
    /**
     * @description: 方案数据，获取副标题
     * @item 当前方案数据
     * @return {*}
     */
    getSubTitle(item) {
      const dt = item.dataType;
      const tp = item.typeFlag;
      let typeName;
      if (dt === 1) { // 系统
        typeName = tp === "000" ? "系统基础数据" : "系统数据字典";
      } else if (dt === 2) { // 方案
        typeName = tp === "000" ? "方案基础数据" : "方案数据字典";
      } else if (dt === 3) { // 自定义
        typeName = tp === "000" ? "自定义基础数据" : "自定义数据字典";
      }
      return typeName;
    },
    /**
     * @description: 查看字段详情
     * @item 当前方案数据
     * @return {*}
     */
    popBaseField(item) {
      this.fieldAttr = item;
    },
    getUseModel(type) {
      if (this.resMap.modules) {
        if (type === 1) {
          return this.resMap.modules.filter((item) => item.type === type);
        }
        return this.resMap.modules.filter((item) => item.type !== 1);
      }
      return [];
    }
  }
};
</script>
<style lang="scss" scoped>
  .plan-detail{
    overflow-y: auto;
    .list-title {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid rgba(232, 236, 242, 1);
      text-align: left;
      display: flex;
      justify-content: space-between;
      background: #fff;
      .title-desc {
        width: 300px;
        padding: 0 20px;
        color: #91a1b7;
        font-size: 12px;
        .icon-cls{
          vertical-align: middle;
        }
        .title {
          color: #333333;
          font-size: 14px;
          font-weight: bold;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .title-btn {
        text-align: right;
        margin-right: 20px;
        & .en-button {
          margin-right: 5px;
        }
      }
    }
    .detail-head{
      background: #fff;
      padding: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .inner-head{
        display: flex;
      }
      .inner-desc{
        padding-left: 20px;
        flex: 1;
        .name{
          font-size: 14px;
          color: #333333;
          font-weight: 400;
        }
        .info{
          font-size: 12px;
          color: #636C78;
          padding-top: 8px;
        }
      }
      .inner-btn{
        width: 200;
        display: flex;
        justify-content: flex-end;
      }
    }
    .detail-use{
      margin-top: 5px;
      .use-list{
        padding: 0 20px;
        background: #fff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        .use-item{
          .use-tit{
            padding: 20px 0;
            font-size: 12px;
            font-weight: bold;
            color: #333333;
          }
          .use-cent{
            display: flex;
            .chk-wrap{
              width:30px;
              .box-in{
                padding-top: 33px;
              }
            }
            .cent-wrap{
              flex: 1;
              border:1px solid #A2CDF7;
              border-radius: 4px;
              margin-bottom: 20px;
              .cent-tit{
                flex: 1;
                .cent-head{
                  height:80px;
                  display: flex;
                  align-items: center;
                  .head-wrap{
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .icon-in{
                      border-radius: 50%;
                      width: 40px;
                      height: 40px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                  .content-wrap{
                    flex: 1;
                    .tit{
                      font-size: 12px;
                      color: #333333;
                    }
                    .info{
                      font-size: 12px;
                      color: #636C78;
                      padding-top: 5px;
                    }
                  }
                  .arrow-wrap{
                    width: 30px;
                    cursor: pointer;
                  }
                }
              }
              .mod-list{
                padding: 0 15px;
                background: #F5F7FA;
                font-size: 12px;
                border-top: 1px solid #E8ECF2;
                .class{
                  &:first-child{
                    padding-top: 10px;
                  }
                  padding-bottom: 10px;
                  .class-name{
                    padding: 20 0px;
                  }
                  .item{
                    padding: 0 20px;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .items{
                      width: 25%;
                      padding-top: 8px;
                    }
                  }
                }
              }
            }
            &:nth-child(3n+1){
              .icon-in{
                background-color: #49bef2;
              }
            }
            &:nth-child(3n+2){
              .icon-in{
                background-color: #4fd2c2;
              }
            }
            &:nth-child(3n+3){
              .icon-in{
                background-color: #f7bf27;
              }
            }
          }
        }
      }
    }
    .detail-data{
      margin-top: 5px;
      .data-list{
        background: #fff;
        padding: 20px;
        .data-tit{
          font-size: 12px;
          font-weight: bold;
          color: #333333;
          padding-bottom: 10px;
        }
        .data-cent{
          display: flex;
          flex-wrap: wrap;
          .data-mod{
            margin-right: 10px;
            margin-top: 10px;
          }
        }
        &:last-child{
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
    .detail-desc{
      margin-top: 5px;
      .desc-list{
        padding: 0 20px;
        background: #fff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-bottom: 5px;
        font-size: 12px;
        .desc-tit{
          padding: 15px 0;
          border-bottom: 1px solid #E8ECF2;
          .version-wrap{
            display: flex;
            justify-content: space-between;
            .version{
              font-weight: bold;
              font-size: 12px;
            }
            .version-date{
              color: #91A1B7;
              font-size: 12px;
            }
          }
          .describe{
            color: #91A1B7;
            padding-top: 8px;
          }
        }
        .desc-content{
          padding: 15px 0;
        }
      }
    }
    .mod{
      border-radius: 8px;
    }
  }
  /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #a3caef;
    border-color: #a3caef;
  }
  /deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #fff;
  }
  .tiyan:hover{
    background-color: #3ce88e!important;
    border:1px solid #3ce88e!important;
  }
</style>
<style lang="scss">

  .plan-use-title-tip{
      padding:0 !important;
      background: #666975 !important;
      border: 1px solid #666975 !important;
      color: #fff !important;
      .popper__arrow{
        border-bottom-color: #666975 !important;
        border-bottom-color: red;
        &::after{
          border-bottom-color: #666975 !important;
        }
      }
      .use-tip-content{
        padding: 6px 5px 5px;
        .cent{
          display: flex;
          .dot{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10px;
            .dot-in{
              width: 2px;
              height: 2px;
              border-radius: 50%;
              background: #26C393;
            }
          }
          .msg{
            flex: 1;
            font-size: 12px;
          }
        }
      }
  }

</style>
