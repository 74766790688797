<!--
 * @Author: zoujp
 * @Date: 2020-11-06 11:29:00
 * @LastEditTime: 2024-02-18 15:06:30
 * @LastEditors: Please set LastEditors
 * @Description: 方案应用-验证安装
 * @FilePath: \user\src\views\plan\planApply\components\installAuth.vue
-->

<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    title="验证管理员身份"
    class="plan-install-auth"
    width="700px"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <div class="form-wrap">
      <div class="mod">
         <el-form ref="myForm" :model="form">
          <div class="account form-item">
              <div class="form-lable">账号</div>
              <el-input  v-model="form.account"  placeholder="请输入管理员账号" autocomplete="off"></el-input>
          </div>
          <div class="password form-item">
              <div class="form-lable">密码</div>
              <el-input  type="password" v-model="form.password"  placeholder="请输入密码" autocomplete="off"></el-input>
          </div>
        </el-form>
      </div>
    </div>
    <div slot="footer">
      <div class="footer-tip">注：需要验证管理员身份才可以安装</div>
      <en-button @click="save" backgroundColor="#3E90FE" class="save-btn">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
// 过滤
export default {
  name: "InstallAuth",
  components: { },
  props: {
  },
  data() {
    return {
      visiable: false,
      form: {
        account: "",
        password: ""
      }
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    save() {
      if (!this.form.account) {
        this.$message.error("请输入管理员账号！");
      } else if (!this.form.password) {
        this.$message.error("请输入密码！");
      } else {
        this.visiable = false;
        this.$emit("authSave", this.form);
      }
    },
    closeFn() {
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .plan-install-auth{
    .form-wrap{
      padding:24px 0 35px 0;
      .mod{
        .form-item{
          display: flex;
          align-items: center;
        }
        .form-lable{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1B1C1E;
          margin-right: 10px;
        }
        .el-input{
          flex: 1;
        }
        .password{
          padding-top: 15px;
        }
      }
    }
    .footer-tip{
      float:left;
      font-size:12px;
      color:#91A1B7;
      padding-top:20px;
    }
  }

  /deep/ .el-dialog__title{
    font-size: 16px!important;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1B1C1E;
  }
  /deep/ .el-dialog__header{
    padding-top: 20px;
  }
  /deep/ .el-dialog__footer{
    padding-bottom: 20px;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close{
    font-size: 16px;
  }
  /deep/ .save-btn:hover{
    background-color: #5a9ffc!important;
  }
</style>
