<!--
 * @Author: zoujp
 * @Date: 2020-11-05 16:34:01
 * @LastEditTime: 2024-02-18 13:43:15
 * @LastEditors: Please set LastEditors
 * @Description: 方案应用
 * @FilePath: \user\src\views\plan\playApply\index.vue
-->
<template>
  <div class="plan-apply" v-en-loading="enloading">
    <en-title-card name="方案应用">
       <div slot="right">
        <en-search
          v-model="searchValue"
          @search="search"
          @change="search"
        >
        </en-search>
        <el-divider direction="vertical" class='vertical-line'></el-divider>
        <div class='install-list-btn' @click="intoInstallLIst">
          <en-icon
            class='icon-left'
            name="iconyianzhuang"
            size="16px"></en-icon>
            <span>已安装</span>
        </div>
      </div>
    </en-title-card>
    <div class="plan-apply-cent">
      <en-tabs
        v-show="!showNodataClass"
        v-model="activeTab"
        type="white"
        :list="tabList"
        :fixedWidth="120"
        @change="changeTabs"
      >
      </en-tabs>
      <div class="panel-wrap">
        <div class="item" v-for="item in schemeList" :key="item.schemeId" @click="toDetail(item)">
          <div class="logo">
            <en-user-logo
              :image-url="item.logoUrl"
              size="40"
              userName=""
            ></en-user-logo>
          </div>
          <div class="desc">
            <div class="name">{{item.name}}</div>
            <div class="info">提供商：{{item.corpName}} {{item.nameToo}}</div>
          </div>
          <div class="btn">
            <div class="btn-cents" v-for="b in item.button.split(',')" :key="b">
              <en-button v-if="b==='installed'" type="white" borderColor="#3e90fe" color="#3e90fe">已安装</en-button>
              <en-button v-else-if="b==='update'" type="white" borderColor="#3e90fe" color="#3e90fe">更新</en-button>
              <en-button v-else-if="b==='unUpdate'" type="white" borderColor="#3e90fe" color="#3e90fe">无法更新</en-button>
              <en-button v-else-if="b==='charge'" type="white" borderColor="#3e90fe" color="#3e90fe" @click="charge(item)">¥ 100</en-button>
              <en-button v-else-if="b==='exp'" type="primary" class="tiyan" backgroundColor="#46d48a" borderColor="#46d48a" @click.native.stop="exp(item)">体验</en-button>
              <en-button v-else-if="b==='install'" type="primary" @click.native.stop="install(item)">安装</en-button>
            </div>
          </div>
        </div>
      </div>
      <en-result type="NoData" v-show="showNodataClass"></en-result>
      <en-result type="NoData" v-show="showNodataItem"></en-result>
    </div>
    <installConfirm  ref="installCfm" @confirmSave="confirmSave"></installConfirm>
    <installAuth  ref="installAut" @authSave="authSave"></installAuth>
  </div>
</template>

<script>
import { request } from "en-js";
import { planApplyService } from "@/api";
import Store from "@/store";
import installConfirm from "./components/installConfirm"; // 过滤
import installAuth from "./components/installAuth"; // 过滤

export default {
  name: "planApply",
  components: { installConfirm, installAuth },
  data() {
    return {
      enloading: false,
      activeTab: "", // 激活的分类id
      tabList: [], // 分类集合
      schemeList: [], // 方案集合
      searchValue: "", // 搜索内容
      showNodataClass: false, // 没有分类是否显示无数据图片
      showNodataItem: false, // 没有方案是否显示无数据图片
      confirmParams: {},
      authParams: {}
    };
  },
  mounted() {
    this.getClassifyList();
  },
  methods: {
    /**
     * @description: 搜索
     * @return {*}
     */
    search() {
      this.querySchemeAllList();
    },
    /**
     * @description: 切换页签
     * @return {*}
     */
    changeTabs() {
      this.querySchemeAllList();
    },
    intoInstallLIst() {
      this.$router.push("installList");
    },
    /**
     * @description: 获取页签列表
     * @return {*}
     */
    @request(true, "enloading")
    async getClassifyList() {
      const pa = {};
      const rsp = await planApplyService.planClassifyQueryList(pa);
      const arr = rsp || [];
      const newArr = arr.filter((item) => item.enableFlag === 0);

      if (newArr.length) {
        this.tabList = newArr;
        this.activeTab = newArr[0].id;
        this.querySchemeAllList();
      } else {
        this.showNodataClass = true;
      }
    },
    /**
     * @description: 获取页签下方案列表
     * @return {*}
     */
    async querySchemeAllList() {
      const pa = {
        classId: this.activeTab, searchValue: this.searchValue, pageNo: 1, pageSize: 9999
      };
      const rsp = await planApplyService.querySchemeAllList(pa);
      this.schemeList = rsp.records || [];
      // this.schemeList = [
      //   {
      //     schemeId: "1",
      //     version: "1",
      //     schemeVersionId: "1",
      //     name: "方案1",
      //     corpName: "提供商1",
      //     nameToo: "rewr",
      //     priceType: "1100",
      //     button: "install,installed,update,unUpdate,charge,exp"
      //   }
      // ];
      if (this.schemeList.length) {
        this.showNodataItem = false;
      } else {
        this.showNodataItem = true;
      }
    },
    /**
     * @description: 跳转到详情
     * @param {*} item
     * @return {*}
     */
    toDetail(item) {
      console.log(item);
      this.$router.push({ path: "/plan/detail", query: { schemeId: item.schemeId, version: item.version, schemeVersionId: item.schemeVersionId } });
    },
    /**
     * @description: 安装
     * @param {*}b 按钮信息
     * @return {*}
     */
    async install(item) {
      const pa = {
        schemeId: item.schemeId, version: item.version
      };
      const res = await this.asyncqueryAdminMulTenant();
      if (!res || !res.length) { // 非本企业 授权
      console.log(111);
        this.installAuthFn(pa);
      } else if (res.length === 1) { // 本企业
      console.log(222);
        await this.installSchema(pa);
      } else { // 多企业
      console.log(333);
        pa.planTenantId = Store.getters.userInfo.tenantId;
        this.installConfirmFn(pa);
      }
    },
    /**
     * @description: 授权安装
     * @param {*} pa
     * @return {*}
     */
    installAuthFn(pa) {
      this.authParams = pa;
      this.$refs.installAut.visiable = true;
    },
    /**
     * @description: 方案安装
     * @param {*} pa
     * @return {*}
     */
    async installSchema(pa) {
      console.log(pa);
      await planApplyService.installScheme(pa);
      this.querySchemeAllList();
    },
    /**
     * @description: 确认安装
     * @param {*} pa
     * @return {*}
     */
    installConfirmFn(pa) {
      this.confirmParams = pa;
      this.$refs.installCfm.visiable = true;
    },
    /**
     * @description: 确认安装回调
     * @param {*}
     * @return {*}
     */
    confirmSave() {
      this.installSchema(this.confirmParams);
    },
    /**
     * @description: 验证安装回调
     * @param {*}
     * @return {*}
     */
    @request(true, "enloading")
    async authSave(pa) {
      const res = await planApplyService.checkAccountPassword(pa);
      const msg = { 1: "验证通过", 2: "账号或密码错误", 3: "账户已锁定，24小时后自动解锁" };
      if (res.result.toString() === "1") {
        this.$message({
          message: msg[res.result],
          type: "success"
        });
      } else {
        this.$message.error(msg[res.result]);
      }
      setTimeout(() => {
        if (res.result.toString() === "1") {
          this.installSchema(Object.assign(this.authParams, pa));
        }
      }, 1000);
    },
    /**
     * @description: 收费
     * @param {*}b 按钮信息
     * @return {*}
     */
    change(item) {
      console.log(item);
    },
    /**
     * @description: 体验
     * @param {*}b 按钮信息
     * @return {*}
     */
    exp(item) {
      console.log(item);
      // window.sessionStorage.setItem("scheme_version_id",item.schemeVersionId);
      // window.location.href = "toLoginList.do";
      this.$router.push({ path: "/plan/loginList", query: { schemeVersionId: item.schemeVersionId } });
    },
    /**
     * @description: 判断是否多企业
     * @param {*}
     * @return {*}
     */
    // @request(true, "enloading")
    async asyncqueryAdminMulTenant() {
      const pa = {
        mobileNo: Store.getters.userInfo.mobileNo
      };
      const rsp = await planApplyService.queryAdminMulTenant(pa);
      console.log(rsp);
      return rsp;
    }
  }
};
</script>
<style lang="scss" scoped>
  .plan-apply{
    .vertical-line{
      height: 32px;
    }
    .install-list-btn{
      display: flex;
      align-content: center;
      cursor: pointer;
      .icon-left{
        align-self: center;
        margin-right: 8px;
        color: #6D8BA7;
      }
    }
    .plan-apply-cent{
      height: calc(100% - 50px);
      overflow-y: auto;
      width: 100%;
      background-color: #ffffff;
      .panel-wrap{
        padding: 0 20px;
        .item{
          display: flex;
          height: 72px;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #E8ECF2;
          .logo{
            width: 60px;
          }
          .desc{
            flex: 1;
            height: 50px;
            .name{
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              padding-top: 5px;
            }
            .info{
              font-size: 12px;
              font-weight: 400;
              color: #636C78;
              padding-top: 6px;
            }
          }
          .btn{
            width: 200px;
            display: flex;
            justify-content: flex-end;
            .btn-cents{
              margin-left: 10px;
              /deep/ .el-button{
                padding: 0;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .tiyan:hover{
    background-color: #3ce88e!important;
    border:1px solid #3ce88e!important;
  }
</style>
