var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.enloading,
          expression: "enloading",
        },
      ],
      staticClass: "myplan",
    },
    [
      _c("en-title-card", { attrs: { name: "我的方案" } }),
      _c(
        "div",
        { staticClass: "myplan-cent" },
        _vm._l(_vm.tablelist, function (item) {
          return _c("dl", { key: item.id, staticClass: "col-mod" }, [
            _c(
              "dt",
              { staticClass: "col-logo" },
              [
                _c("en-user-logo", {
                  attrs: {
                    "user-name": "",
                    width: "50",
                    height: "50",
                    "image-url": item.logoUrl,
                  },
                }),
              ],
              1
            ),
            _c("dd", { staticClass: "col-name" }, [_vm._v(_vm._s(item.name))]),
            _c(
              "dd",
              {
                staticClass: "col-state",
                style: { color: _vm.planStatus[item.status].color },
              },
              [
                _vm._v(
                  _vm._s(item.version) +
                    " " +
                    _vm._s(_vm.planStatus[item.status].name)
                ),
              ]
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }