<!--
 * @Author: zhulin
 * @Date: 2020-08-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-20 15:57:27
 * @Description: 筛选
 * @FilePath: \user\src\views\plan\salesTrends\components\screenAlert.vue
-->
<template>
  <en-dialog title="筛选" :visible.sync="show" en-body-scroll @close="closeDialog" class="screen-alert" width="700px">
    <el-form ref="myForm" :model="myForm" label-width="60px" label-position="left">
      <el-form-item label="方案名称" prop="schemeId">
        <el-select v-model="myForm.schemeId" multiple collapse-tags placeholder="全部" style="width:100%" @change="sChange">
          <el-option
            v-for="item in nameOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <!-- <en-select v-model="myForm.schemeId" placeholder="请选择" :data="nameOption || []" :props="defaultProps" data-mode="data" multiple></en-select> -->
      </el-form-item>
      <el-form-item label="时间段" prop="createTxt">
        <el-date-picker
          v-model="myForm.createTxt"
          type="datetimerange"
          format="yyyy-MM-dd HH:mm:ss"
          range-separator="—"
          start-placeholder=""
          end-placeholder=""
          align="right"
          size="small"
          :default-time="['12:00:00', '08:00:00']"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="submitForm">确定</en-button>
    </span>
  </en-dialog>
</template>

<script>
import dayjs from "dayjs";
import { salesTrendsService } from "@/api";

export default {
  props: {
    show: {
      type: Boolean
    },
    screenData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      nameOption: [],
      schemeList: [],
      pickerOptions: {
          shortcuts: [{
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "今年",
            onClick(picker) {
              const year = dayjs().year() - 1;
              const start = `${year}-01-01`;
              const end = `${year}-12-31`;
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "去年",
            onClick(picker) {
              const year = dayjs().year() - 2;
              const start = `${year}-01-01`;
              const end = `${year}-12-31`;
              picker.$emit("pick", [start, end]);
              picker.$emit("pick", [start, end]);
            }
          }]
      },
      myForm: {
        schemeId: "",
        createTxt: "",
        schemeName: ""
      }
      // defaultProps: {
      //   label: "label",
      //   value: "value",
      //   assist: "assist"
      // }
    };
  },
  mounted() {
    this.querySchemeList();
  },
  watch: {
    /**
     * @description: 监听弹窗
     */
    show(val) {
      if (val) {
        this.myForm = JSON.parse(JSON.stringify(this.screenData)) || [];
      }
    }
  },
  methods: {
    /**
     * @description: 查询方案
     */
    async querySchemeList() {
      const rsp = await salesTrendsService.querySchemeList({ pageNo: 1, pageSize: 100000000 });
      this.schemeList = rsp.records || [];
      this.nameOption = this.schemeList.map((item) => ({
        value: item.schemeId,
        label: item.name
      }));
    },
    sChange(idArr) {
      const opt = idArr.map((id) => this.nameOption.find((col) => col.value === id));
      this.myForm.schemeName = opt.map((col) => col.label).join(",");
    },
    /**
     * @description: 关闭弹窗回调
     */
    closeDialog() {
      this.$emit("toShow", false);
    },
    /**
     * @description: 提交事件
     */
    submitForm() {
      this.$emit("toShow", false, this.myForm);
    }
  }
};
</script>

<style lang="scss" scoped>
.screen-alert {
  .en-select {
    width: 100%;
  }
  .el-range-editor--small.el-input__inner {
    width: 100%;
  }
}
.el-form-item{
  margin-bottom: 10px;
}
/deep/ .el-tag.el-tag--info{
  background-color: #f5f8fc;
  padding: 0 10px;
  border-color: #dce5ec;
  color: #636c78;
  margin-top: 2px;
}
/deep/ .el-select .el-tag__close.el-icon-close{
  background: none;
  font-size: 18px;
  top: -1px;
  &:hover{
    color:#636c78;
  }
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected{
  color:#409EFF;
  font-weight: normal;
}
/deep/ .el-input__inner{
  border:1px solid #DCDFE6
}
/deep/ .el-range-separator{
  color:#aeaeae
}
</style>
