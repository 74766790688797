var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "en-dialog",
        {
          staticClass: "plan-install-confirm",
          attrs: {
            visible: _vm.visiable,
            title: "",
            width: "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeFn,
          },
        },
        [
          _c("div", { staticClass: "form-wrap" }, [
            _c("div", { staticClass: "mod" }, [
              _c(
                "div",
                { staticClass: "logo" },
                [
                  _c("en-icon", {
                    attrs: { name: "tishi", color: "#3e90fe", size: "30" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "title" }, [
                _c("div", { staticClass: "main" }, [
                  _vm._v("当前用户存在多个企业，安装到当前企业？"),
                ]),
                _c("div", { staticClass: "sub" }, [
                  _vm._v("可点击右上角切换到其他企业安装"),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [_c("en-button", { on: { click: _vm.save } }, [_vm._v(" 确定 ")])],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }