<!--
 * @Author: zoujp
 * @Date: 2020-11-06 15:45:36
 * @LastEditTime: 2021-08-05 09:31:44
 * @LastEditors: Please set LastEditors
 * @Description: 人员登录
 * @FilePath: \user\src\views\plan\loginList\index.vue
-->
<template>
  <div class="plan-login-list" v-en-loading="enloading">
    <div class="list-title">
      <div class="title-desc" @click="back">
        <en-icon name="iconfanhuishangcengji" size="small" color="#A9B5C6" class="icon-cls"> </en-icon>
        <span class="title">人员登录</span>
      </div>
    </div>
    <div class="login-wrap">
      <en-table
          :data="tableData"
          :height-config="{bottomHeight:15}"
          :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }"
        >
          <en-table-column
            title="姓名"
            field="name"
          >
          </en-table-column>
          <en-table-column
            title="机构"
            field="postName"
          >
          </en-table-column>
          <en-table-column
            title="部门"
            field="deptName"
          >
          </en-table-column>
          <template #hoverRow="{row, rowIndex}">
            <en-expand-buttons
              :data="btns"
              @button-click="handleButtonClick(row,rowIndex, arguments[0])"
            />
          </template>
      </en-table>
    </div>
  </div>
</template>

<script>
import { request } from "en-js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { loginService, publicService, planDetailService } from "@/api";
import Store from "@/store";
import API from "@/api/homeMenu";

export default {
  name: "loginList",
  components: { },
  data() {
    return {
      enloading: false,
      tableData: [],
      pageModel: {
        pageNo: 1,
        pageSize: 10

      },
      btns: [
        {
          id: "login",
          name: "直接登录",
          icon: "shenhe1",
          iconColor: "#26c393"
        }
      ]
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters(["loginAccount"])
  },
  methods: {
    ...mapActions(["login", "logout", "updateUserInfo", "saveUrlParam", "saveLoginAccount"]),
    ...mapMutations("main", ["setMenuData"]),
    /**
     * @description: 获取页签列表
     * @return {*}
     */
    @request(true, "enloading")
    async getList() {
      const pa = { ...this.pageModel };
      pa.schemeVersionId = this.$route.query.schemeVersionId;
      const rsp = await planDetailService.querySchemeUserList(pa);
      this.tableData = rsp.records || [];
    },
    handleButtonClick(row, index) {
      // 直接登陆
      if (index === 0) {
        this.loginAction(row);
      }
    },
    @request(true, "enloading")
    async loginAction(atd) {
      const pa = {
        username: atd.mobileNo,
        account: atd.mobileNo,
        password: atd.authPassword,
        oldUserId: Store.getters.userInfo.userId,
        machineType: "PC",
        mobileNo: Store.getters.userInfo.mobileNo,
        experience: 1
      };
      const rsp = await loginService.login(pa);

      this.updateUserInfo(rsp);
      const urlConfig = await publicService.getUploadUrl();
      this.saveUrlParam(urlConfig);
      const data = await API.getMenu();
      this.setMenuData(data);
      this.saveLoginAccount(pa.account);
      // 需要调取登录存用户信息
      this.$router.push({ path: "/home" });
      // 顶部右侧个人信息未刷新手动刷新
      window.location.reload();
    },
    handlePageChanged({ pageNo, pageSize }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.getList();
    },
     back() {
       this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
  .plan-login-list{
    .login-wrap{
      height: calc(100% - 50px);
      background: #fff;
    }
    .list-title {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid rgba(232, 236, 242, 1);
        text-align: left;
        display: flex;
        background-color: #fff;
        justify-content: space-between;
        .title-desc {
          width: 300px;
          padding: 0 20px;
          color: #91a1b7;
          font-size: 12px;
          .icon-cls{
            vertical-align: middle;
          }
          .title {
            color: #333333;
            font-size: 14px;
            font-weight: bold;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
  }
</style>
