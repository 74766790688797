<!--
 * @Author: zoujp
 * @Date: 2020-11-10 08:56:06
 * @LastEditTime: 2021-08-03 15:54:35
 * @LastEditors: Please set LastEditors
 * @Description: 对比日志
 * @FilePath: \user\src\views\plan\detail\components\compareLog.vue
-->
<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    title="对比日志"
    width="800px"
    class="com-log-wrap"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <div class="compare-wrap" v-en-loading="enloading">
      <en-table :data="dataList" height="400px">
        <en-table-column width="50" type="index"></en-table-column>
        <en-table-column
          prop="templateName"
          label="模板名称"
          width="180"
        >
        </en-table-column>
        <en-table-column
          prop="templateType"
          label="模板类型"
          width="20%"
        >
          <template v-slot="{ row }">
              {{ mapType[row.templateType] }}
          </template>
        </en-table-column>
        <en-table-column
          prop="comparisonLog"
          label="对比日志"
        >
        </en-table-column>
      </en-table>
    </div>
     <div slot="footer">
    </div>
  </en-dialog>
</template>

<script>
import { request } from "en-js";
import { planDetailService } from "@/api";

export default {
  name: "CompareLog",
  components: { },
  props: {
    schemeId: {
      type: String
    },
    schemeVersion: {
      type: String
    }
  },
  data() {
    return {
      enloading: false,
      visiable: false,
      mapType: {
        1: "基础数据", 2: "方案基础数据", 3: "自定义基础数据", 4: "自定义数据字典", 5: "方案数据字典"
      },
      dataList: []
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getList();
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * @description: 获取对比日志
     * @return {*}
     */
    @request(true, "enloading")
    async getList() {
      const pa = {
        schemeId: this.schemeId,
        schemeVersion: this.schemeVersion,
        pageNo: 1,
        pageSize: 99999

      };
      const res = await planDetailService.querySchemeComparisonLogList(pa);
      this.dataList = res.records || [];
    },
    closeFn() {
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog .el-dialog__body{
  padding: 0 10px;
}
  .com-log-wrap{
    overflow-y: auto;
  }
</style>
