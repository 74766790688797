var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "en-dialog",
        {
          attrs: {
            visible: _vm.visiable,
            title: "版本记录",
            width: "800px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeFn,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.enloading,
                  expression: "enloading",
                },
              ],
              staticClass: "detail-wrap",
            },
            _vm._l(_vm.dataList, function (item) {
              return _c("div", { key: item.id, staticClass: "mod" }, [
                _c("div", { staticClass: "tit" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.version)),
                  ]),
                  _c("div", { staticClass: "time" }, [
                    _vm._v(_vm._s(item.createTime)),
                  ]),
                ]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(item.content)),
                ]),
              ])
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }