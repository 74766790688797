var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visiable
    ? _c(
        "en-dialog",
        {
          attrs: {
            visible: _vm.visiable,
            title: _vm.fieldAttr.name,
            width: "800px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiable = $event
            },
            close: _vm.closeFn,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "en-loading",
                  rawName: "v-en-loading",
                  value: _vm.enloading,
                  expression: "enloading",
                },
              ],
              staticClass: "field-wrap",
            },
            [
              _c(
                "en-table",
                {
                  attrs: {
                    data: _vm.tableData,
                    "height-config": { bottomHeight: 15 },
                    "page-config": {
                      pageModel: _vm.pageModel,
                      changeMethod: _vm.handlePageChanged,
                    },
                  },
                },
                [
                  _c("en-table-column", { attrs: { type: "index-selection" } }),
                  (_vm.fieldAttr.dataType === 2 ||
                    _vm.fieldAttr.dataType === 3) &&
                  _vm.fieldAttr.typeFlag === "000"
                    ? _c("en-table-column", {
                        attrs: { title: "字段", field: "name" },
                      })
                    : _vm._e(),
                  (_vm.fieldAttr.dataType === 2 ||
                    _vm.fieldAttr.dataType === 3) &&
                  _vm.fieldAttr.typeFlag === "000"
                    ? _c("en-table-column", {
                        attrs: { title: "字段类型", field: "fieldType" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _vm._v(" " + _vm._s(row.fieldType) + " "),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1613317093
                        ),
                      })
                    : _vm._e(),
                  (_vm.fieldAttr.dataType === 2 &&
                    _vm.fieldAttr.typeFlag !== "000") ||
                  (_vm.fieldAttr.dataType === 3 &&
                    _vm.fieldAttr.typeFlag !== "000")
                    ? _c("en-table-column", {
                        attrs: { title: "名称", field: "name" },
                      })
                    : _vm._e(),
                  (_vm.fieldAttr.dataType === 2 &&
                    _vm.fieldAttr.typeFlag !== "000") ||
                  (_vm.fieldAttr.dataType === 3 &&
                    _vm.fieldAttr.typeFlag !== "000")
                    ? _c("en-table-column", {
                        attrs: { title: "编码", field: "code" },
                      })
                    : _vm._e(),
                  _vm.fieldAttr.dataType === 2
                    ? _c("en-table-column", {
                        attrs: { title: "操作", field: "comparisonLog" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }