<!--
 * @Author: zhulin
 * @Date: 2020-8-22 14:59:10
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-20 15:39:30
 * @Description: 销售与趋势
 -->
<template>
  <div class="sales-trend" id="content" v-en-loading="enloading">
    <en-title-card name="销售与趋势">
      <div slot="right">
        <div class="button-mod" @click="screenChange">
          <en-icon name="iconshaixuan" size="small"></en-icon>
          <span class="label">
            筛选
          </span>
        </div>
      </div>
    </en-title-card>
    <div class="sales-cent">
      <div class="screen-list" v-if="screenData.schemeName || screenData.createTxt.length">
        <ul class="list">
          <li class="mods" v-if="screenData.schemeName">
            <span class="txts">方案名称：{{ screenData.schemeName }}</span
            ><en-icon name="iconshaixuantiaojianshanchu21" size="small" @click.native="emptyClick(1)"></en-icon>
          </li>
          <li class="mods" v-if="screenData.createTxt.length">
            <span class="txts">时间段：{{ screenData.createTxt.join("至") }}</span
            ><en-icon name="iconshaixuantiaojianshanchu21" size="small" @click.native="emptyClick(2)"></en-icon>
          </li>
        </ul>
        <span class="empty-link" @click="emptyClick(3)">清空</span>
      </div>
      <div class="floor" v-if="chartsDataX.xData.length">
        <div class="head"><span class="txts">趋势</span></div>
        <div class="cent">
          <en-charts
            v-if="chartsDataX.xData.length&&showChart"
            scroll-id="content"
            :x-data="chartsDataX.xData"
            :y-data="chartsDataX.yData"
            class="charts-item"
            charts-type="lineX"
            data-label="name"
            data-key="data"
            stack="分数"
            gridList="60px,60px"
          >
          </en-charts>
          <en-result type="NoData" v-else></en-result>
        </div>
      </div>
      <div class="floor"  v-if="tablelist.length">
        <div class="head"><span class="txts">销售</span></div>
        <div class="cent">
          <en-table :data="tablelist" @row-click="rowClickEvent">
            <en-table-column type="index" width="60px"></en-table-column>
            <en-table-column prop="schemeName" label="方案名称"> </en-table-column>
            <en-table-column prop="totals" label="销售数量"> </en-table-column>
          </en-table>
          <!-- <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination> -->
        </div>
      </div>
      <en-result type="NoData" v-else></en-result>
    </div>
    <screenAlert ref="screenAlert" :show="showScreenAlert" :screenData="screenData" @toShow="toShow" />
    <screenList ref="screenList" :show="showscreenList" :listData="listData"  @toclose="toclose" />
  </div>
</template>

<script>
import { request } from "en-js";
import dayjs from "dayjs";
import { salesTrendsService } from "@/api";
import screenAlert from "./components/screenAlert";
import screenList from "./components/screenList";

export default {
  name: "salesTrend",
  components: { screenAlert, screenList },
  data() {
    return {
      showScreenAlert: false,
      showscreenList: false,
      screenData: {
        schemeId: "",
        createTime: "",
        createTxt: [],
        schemeName: ""
      },
      allSchemeList: [],
      tablelist: [],
      chartList: [],
      chartsDataX: {
        yData: [
          {
            name: "",
            data: []
          }
        ],
        xData: []
      },
      listData: {},
      enloading: false,
      showChart: false
    };
  },
  mounted() {
    // 取当月第一天到当天
    const startDaty = dayjs().startOf("month").format("YYYY-MM-DD");
    const nowDay = dayjs().format("YYYY-MM-DD");
    this.screenData.createTxt = [startDaty, nowDay];
    this.getData();
  },
  methods: {
    /**
     * @description: 获取数据
     */
    getData() {
      this.queryRefCorpList();
      this.queryRefCorpDetail();
    },
    /**
     * @description: 查询方案
     */
    @request(true, "enloading")
    async queryRefCorpList() {
      const pa = this.getParams();
      const rsp = await salesTrendsService.queryRefCorpList(pa);
      const arr = rsp || [];
      this.tablelist = arr;
    },
    @request(true, "enloading")
    async queryRefCorpDetail() {
      this.showChart = false;
      const pa = this.getParams();
      const rsp = await salesTrendsService.queryRefCorpDetail(pa);
      const arr = rsp || [];
      this.handleChartData(arr);
    },
    /**
     * @description: 处理图表数据
     */
    handleChartData(data) {
      this.showChart = true;
      data.sort((a, b) => b.createTime - a.createTime).reverse();
      let legend = [];
      let xAxis = [];
      const yData = [];
      data.forEach((item) => {
        legend.push(item.schemeName);
        xAxis.push(item.createTime);
      });
      legend = [...new Set(legend)];
      xAxis = [...new Set(xAxis)];
      legend.forEach((item) => {
        const _d = [];
        xAxis.forEach(() => _d.push(0));
        yData.push({
          name: item,
          data: _d
        });
      });
      yData.forEach((item) => {
        const name = item.name;
        const _data = item.data;
        _data.forEach((item1, index) => {
          const date = xAxis[index];
          data.forEach((item2) => {
            if (name === item2.schemeName && date === item2.createTime) {
              _data.splice(index, 1, parseInt(item2.totals, 10));
            }
          });
        });
      });
      const lastData = {};
      lastData.yData = yData;
      lastData.xData = xAxis;
      this.chartsDataX = lastData;
    },
    getParams() {
      const pa = {};
      if (this.screenData.schemeId) {
        pa.schemeId = this.screenData.schemeId.join(",");
      }
      if (this.screenData.createTxt.length) {
        pa.createTime = this.screenData.createTxt.join(",");
      }
      return pa;
    },
    /**
     * @description: 弹窗
     */
    screenChange() {
      this.showScreenAlert = true;
    },
    /**
     * @description: 清空
     */
    emptyClick(tag) {
      switch (tag) {
        case 1:
          this.screenData.schemeId = "";
          this.screenData.schemeName = "";
          break;
        case 2:
          this.screenData.createTime = "";
          this.screenData.createTxt = [];
          break;
        case 3: // 清空
          this.screenData.schemeId = "";
          this.screenData.schemeName = "";
          this.screenData.createTime = "";
          this.screenData.createTxt = [];
          break;
        default:
          break;
      }
      this.getData();
    },
    /**
     * @description: 关闭弹窗
     */
    toShow(show, data) {
      this.showScreenAlert = show;
      if (data) {
        let createTime = [];
        if (data.createTxt) {
          createTime = data.createTxt.map((item) => dayjs(item).format("YYYY-MM-DD"));
        }
        this.screenData = {
          schemeId: data.schemeId || "",
          createTime: data.createTime || "",
          schemeName: data.schemeName,
          createTxt: createTime
        };
        this.getData();
        this.$forceUpdate();
      }
    },
    toclose() {
      this.showscreenList = false;
    },
    rowClickEvent({ row }) {
      const pa = {};
      pa.schemeId = row.schemeId;
      if (this.screenData.createTxt.length) {
        pa.createTime = this.screenData.createTxt.join(",");
      }
      this.listData = pa;
      this.showscreenList = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.sales-trend {
  background: #fff;
  .button-mod {
    cursor: pointer;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      color: #9367eb;
    }
    .label {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .screen-list {
    border-bottom: 1px solid #f3f3f3;
    padding: 10px 20px;
    display: flex;
    .list {
      padding: 0;
      margin: 0;
    }
    .empty-link {
      line-height: 26px;
      font-size: 12px;
      cursor: pointer;
      color: #4895df;
    }
    .mods {
      padding: 0 10px;
      margin-right: 12px;
      line-height: 24px;
      border-radius: 5px;
      border: 1px solid #dce5ec;
      color: #8e8f91;
      display: inline-block;
      vertical-align: middle;
      background: #f5f8fc;
      .txts {
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
      }
      .en-icon {
        margin-left: 8px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
  .en-charts {
    width: 90%;
    margin: 0 auto;
  }
  .floor {
    .head {
      padding: 20px 20px;
      font-weight: bold;
      font-size: 12px;
    }
  }
  .sales-cent {
    height: calc(100% - 50px);
    overflow-y: auto;
    width: 100%;
  }
}
</style>
