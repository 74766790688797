var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      attrs: {
        title: "销售明细",
        visible: _vm.show,
        "en-body-scroll": "",
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "list-alert" },
        [
          _c(
            "en-table",
            { attrs: { data: _vm.schemeList } },
            [
              _c("en-table-column", {
                attrs: { prop: "schemeName", label: "方案名称" },
              }),
              _c("en-table-column", {
                attrs: { prop: "createTime", label: "销售日期" },
              }),
              _c("en-table-column", {
                attrs: { prop: "totals", width: "100px", label: "销售数量" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }