<!--
 * @Author: zoujp
 * @Date: 2020-11-06 12:00:39
 * @LastEditTime: 2020-11-06 14:10:57
 * @LastEditors: Please set LastEditors
 * @Description: 方案应用-确认安装
 * @FilePath: \user\src\views\plan\planApply\components\installConfirm.vue
-->

<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    title=""
    class="plan-install-confirm"
    width="500px"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <div class="form-wrap">
      <div class="mod">
        <div class="logo">
          <en-icon
            name="tishi"
            color="#3e90fe"
            size="30"
          >
          </en-icon>
        </div>
        <div class="title">
          <div class="main">当前用户存在多个企业，安装到当前企业？</div>
          <div class="sub">可点击右上角切换到其他企业安装</div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </en-dialog>
</template>

<script>
// 过滤
export default {
  name: "InstallConfirm",
  components: { },
  props: {
  },
  data() {
    return {
      visiable: false
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    save() {
      this.visiable = false;
      this.$emit("confirmSave");
    },
    closeFn() {
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .plan-install-confirm{
    .form-wrap{
      .mod{
        padding: 20px;
        display: flex;
        justify-content: flex-start;
        .logo{
          width:45px;
        }
        .title{
          flex:1;
          .main{
            color: #333333;
            font-size: 14px;
            font-weight: bold;
          }
          .sub{
            padding-top: 8px;
            color: #636C78;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
</style>
