<!--
 * @Author: zoujp
 * @Date: 2020-11-10 16:21:08
 * @LastEditTime: 2021-07-09 17:12:54
 * @LastEditors: Please set LastEditors
 * @Description: 基础数据字段
 * @FilePath: \user\src\views\plan\detail\components\baseField.vue
-->
<template>
  <en-dialog
    v-if="visiable"
    :visible.sync="visiable"
    :title="fieldAttr.name"
    width="800px"
    :close-on-click-modal="false"
    @close="closeFn"
  >
    <div class="field-wrap" v-en-loading="enloading">
      <en-table
          :data="tableData"
          :height-config="{bottomHeight:15}"
          :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }"
        >
          <en-table-column type="index-selection"></en-table-column>

          <en-table-column v-if="(fieldAttr.dataType === 2 || fieldAttr.dataType === 3) && fieldAttr.typeFlag === '000'"
            title="字段"
            field="name"
          >
          </en-table-column>
           <en-table-column v-if="(fieldAttr.dataType === 2 || fieldAttr.dataType === 3) && fieldAttr.typeFlag === '000'"
            title="字段类型"
            field="fieldType"
          >
             <template v-slot="{ row}">
              {{ row.fieldType }}
            </template>
           </en-table-column>

           <en-table-column v-if="(fieldAttr.dataType === 2 && fieldAttr.typeFlag !== '000') || fieldAttr.dataType === 3 && fieldAttr.typeFlag !== '000'"
            title="名称"
            field="name"
          >
          </en-table-column>
           <en-table-column v-if="(fieldAttr.dataType === 2 && fieldAttr.typeFlag !== '000') || fieldAttr.dataType === 3 && fieldAttr.typeFlag !== '000'"
            title="编码"
            field="code"
          >
          </en-table-column>

           <en-table-column v-if="fieldAttr.dataType === 2"
            title="操作"
            field="comparisonLog"
          >
          </en-table-column>

      </en-table>
    </div>
  </en-dialog>
</template>

<script>
import { request } from "en-js";
import { planDetailService } from "@/api";

export default {
  name: "BaseField",
  components: { },
  props: {
    fieldAttr: {
      type: Object,
      default() {
        return {};
      }
    },
    schemeId: {
      type: String
    },
    schemeVersion: {
      type: String
    }
  },
  data() {
    return {
      enloading: false,
      visiable: false,
      tableData: [],
      pageModel: { // 分页
        pageSize: 10,
        pageNo: 1,
        schemeId: this.schemeId,
        schemeVersion: this.schemeVersion,
        objTypeId: this.fieldAttr.id,
        isPage: 0
      }
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getList();
      }
    }
  },
  mounted() {
    // this.getList();
  },
  methods: {
    /**
     * @description: 分页
     * @return {*}
     */
    handlePageChanged({ pageNo, pageSize }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.getList();
    },
    /**
     * @description: 获取版本记录
     * @return {*}
     */
    @request(true, "enloading")
    async getList() {
      const res = await planDetailService.querySchemeComparisonLog(this.pageModel);
      this.tableData = res.records || [];
    },
    closeFn() {
      this.visiable = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .field-wrap{

  }
</style>
