<!--
 * @Author: wuqi
 * @Date: 2020-11-06 15:45:36
 * @LastEditTime: 2021-07-30 11:06:17
 * @LastEditors: wuqi
 * @Description: 已安装方案
 * @FilePath: \user\src\views\plan\loginList\index.vue
-->
<template>
  <div class="plan-login-list" v-en-loading="enloading">
    <en-title-card >
      <div slot="left" class='header-top'>
         <en-icon class='back-icon' name="fanhui" size="16px" @click.native.stop="$router.go(-1)"></en-icon>
         <span>已安装方案</span>
      </div>
    </en-title-card>
    <div class="login-wrap">
      <en-table
          :data="tableData"
          :height-config="{bottomHeight:15}"
          @row-click="handleRowClick"
          :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }"
        >
        <en-table-column type="index" width="60"></en-table-column>
        <en-table-column
            title="方案名称"
            field="name"
          >
          </en-table-column>
          <en-table-column
            title="方案版本"
            field="version"
          >
          </en-table-column>
          <en-table-column
            title="安装版本"
            field="installVersion"
          >
          </en-table-column>
          <en-table-column
            title="安装时间"
            field="createTime"
          >
          </en-table-column>
      </en-table>
    </div>
  </div>
</template>

<script>
import { request } from "en-js";
import { planApplyService } from "@/api";

export default {
  name: "loginList",
  components: { },
  data() {
    return {
      enloading: false,
      tableData: [],
      pageModel: {
        pageNo: 1,
        pageSize: 10

      },
      btns: [
        {
          id: "login",
          name: "直接登录",
          icon: "shenhe1",
          iconColor: "#26c393"
        }
      ]
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleRowClick({ row }) {
      console.log(row);
       this.$router.push({ path: "/plan/detail", query: { schemeId: row.schemeId, version: row.version } });
    },
    /**
     * @description: 获取页签列表
     * @return {*}
     */
    @request(true, "enloading")
    async getList() {
      const pa = {};
      const rsp = await planApplyService.queryInstalledSchemeList(pa);
      this.tableData = rsp.records || [];
    },

    handlePageChanged({ pageNo, pageSize }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.getList();
    }
  }
};
</script>
<style lang="scss" scoped>
  .plan-login-list{
    .login-wrap{
      height: calc(100% - 50px);
      background: #fff;
    }
    & /deep/ .en-title-card-left-component{
      margin-left: 0;
    }
    .header-top{
      display:flex;
      align-items: center;
      .back-icon{
        vertical-align: sub;
        cursor: pointer;
      }

    }
  }
</style>
