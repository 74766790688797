var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "en-loading",
          rawName: "v-en-loading",
          value: _vm.enloading,
          expression: "enloading",
        },
      ],
      staticClass: "plan-detail",
    },
    [
      _c("div", { staticClass: "list-title" }, [
        _c(
          "div",
          { staticClass: "title-desc", on: { click: _vm.back } },
          [
            _c("en-icon", {
              staticClass: "icon-cls",
              attrs: {
                name: "iconfanhuishangcengji",
                size: "small",
                color: "#A9B5C6",
              },
            }),
            _c("span", { staticClass: "title" }, [_vm._v("方案详情")]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "title-btn" },
          [
            _c(
              "en-button",
              {
                attrs: {
                  icon: "rizhi",
                  iconColor: "#A9B5C6",
                  iconSize: "16",
                  color: "#333333",
                  text: "",
                },
                on: { click: _vm.versionRecord },
              },
              [_vm._v(" 版本记录 ")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "detail-head" }, [
        _c(
          "div",
          { staticClass: "inner-head" },
          [
            _c("en-user-logo", {
              attrs: {
                "user-name": "",
                "image-url": _vm.resMap.logoUrl,
                size: "80",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "inner-desc" }, [
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.resMap.name))]),
          _c("div", { staticClass: "info" }, [
            _vm._v(
              "提供商：" +
                _vm._s(_vm.resMap.corpName) +
                " 专家 " +
                _vm._s(_vm.resMap.nameToo) +
                " ¥ " +
                _vm._s(_vm.resMap.priceType)
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "inner-btn" },
          [
            _vm.resMap.installFlag === 0
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      type: "white",
                      borderColor: "#3e90fe",
                      color: "#3e90fe",
                    },
                  },
                  [_vm._v("已安装")]
                )
              : _c(
                  "en-button",
                  { attrs: { type: "primary" }, on: { click: _vm.install } },
                  [_vm._v("安装")]
                ),
            _c(
              "en-button",
              {
                staticClass: "tiyan",
                attrs: {
                  type: "primary",
                  backgroundColor: "#46d48a",
                  borderColor: "#46d48a",
                },
                on: { click: _vm.exp },
              },
              [_vm._v("体验")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "detail-use mod" },
        [
          _c("en-title-card", { attrs: { name: "方案使用模块" } }, [
            _c(
              "div",
              { attrs: { slot: "left" }, slot: "left" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      "popper-class": "plan-use-title-tip",
                      placement: "bottom-start",
                      title: "",
                      width: "480",
                      trigger: "hover",
                    },
                  },
                  [
                    _c("div", { staticClass: "use-tip-content" }, [
                      _c("div", { staticClass: "cent" }, [
                        _c("div", { staticClass: "dot" }, [
                          _c("div", { staticClass: "dot-in" }),
                        ]),
                        _c("div", { staticClass: "msg" }, [
                          _vm._v(
                            "建模模块：模块的安装不影响安装此方案企业的配置"
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "cent" }, [
                        _c("div", { staticClass: "dot" }, [
                          _c("div", { staticClass: "dot-in" }),
                        ]),
                        _c("div", { staticClass: "msg" }, [
                          _vm._v(
                            "其他模块：模块的安装将会影响方案本身的配置以及方案审核的结果，请慎重选择"
                          ),
                        ]),
                      ]),
                    ]),
                    _c("en-icon", {
                      attrs: {
                        slot: "reference",
                        name: "tishi",
                        size: "16",
                        color: "#F7BF27",
                      },
                      slot: "reference",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "use-list" }, [
            _c(
              "div",
              { staticClass: "use-item" },
              [
                _c("div", { staticClass: "use-tit" }, [_vm._v("建模模块")]),
                _vm._l(_vm.getUseModel(1), function (item) {
                  return _c("div", { key: item.id, staticClass: "use-cent" }, [
                    _c("div", { staticClass: "chk-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "box-in" },
                        [
                          _c("el-checkbox", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.checked,
                              callback: function ($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "cent-wrap" }, [
                      _c("div", { staticClass: "cent-tit" }, [
                        _c("div", { staticClass: "cent-head" }, [
                          _c("div", { staticClass: "head-wrap" }, [
                            _c(
                              "div",
                              { staticClass: "icon-in" },
                              [
                                _c("en-icon", {
                                  attrs: {
                                    name: "icon-pnan-" + item.icon,
                                    size: "20",
                                    color: "#fff",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "content-wrap" }, [
                            _c("div", { staticClass: "tit" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("div", { staticClass: "info" }, [
                              _vm._v(_vm._s(item.description)),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "arrow-wrap",
                              on: {
                                click: function ($event) {
                                  return _vm.expand(item)
                                },
                              },
                            },
                            [
                              _c("en-icon", {
                                attrs: {
                                  name: item.isShow ? "zhankai1" : "shouqi",
                                  size: "14",
                                  color: "#75AADC",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.isShow,
                              expression: "item.isShow",
                            },
                          ],
                          staticClass: "mod-list",
                        },
                        _vm._l(item.detailList, function (cls) {
                          return _c(
                            "div",
                            { key: cls.id, staticClass: "class" },
                            [
                              _c(
                                "div",
                                { staticClass: "class-name" },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.checked,
                                        callback: function ($$v) {
                                          _vm.checked = $$v
                                        },
                                        expression: "checked",
                                      },
                                    },
                                    [_vm._v(_vm._s(cls.name))]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "item" },
                                _vm._l(cls.detailList, function (mod) {
                                  return _c(
                                    "div",
                                    { key: mod.id, staticClass: "items" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.checked,
                                            callback: function ($$v) {
                                              _vm.checked = $$v
                                            },
                                            expression: "checked",
                                          },
                                        },
                                        [_vm._v(_vm._s(mod.name))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "use-item" },
              [
                _c("div", { staticClass: "use-tit" }, [_vm._v("其他模块")]),
                _vm._l(_vm.getUseModel(2), function (item) {
                  return _c("div", { key: item.id, staticClass: "use-cent" }, [
                    _c("div", { staticClass: "chk-wrap" }, [
                      _c(
                        "div",
                        { staticClass: "box-in" },
                        [
                          _c("el-checkbox", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.checked,
                              callback: function ($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "cent-wrap" }, [
                      _c("div", { staticClass: "cent-tit" }, [
                        _c("div", { staticClass: "cent-head" }, [
                          _c("div", { staticClass: "head-wrap" }, [
                            _c(
                              "div",
                              { staticClass: "icon-in" },
                              [
                                _c("en-icon", {
                                  attrs: {
                                    name: "icon-pnan-" + item.icon,
                                    size: "20",
                                    color: "#fff",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "content-wrap" }, [
                            _c("div", { staticClass: "tit" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("div", { staticClass: "info" }, [
                              _vm._v(_vm._s(item.description)),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "arrow-wrap",
                              on: {
                                click: function ($event) {
                                  return _vm.expand(item)
                                },
                              },
                            },
                            [
                              _c("en-icon", {
                                attrs: {
                                  name: item.isShow ? "zhankai1" : "shouqi",
                                  size: "14",
                                  color: "#75AADC",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.isShow,
                              expression: "item.isShow",
                            },
                          ],
                          staticClass: "mod-list",
                        },
                        _vm._l(item.detailList, function (cls) {
                          return _c(
                            "div",
                            { key: cls.id, staticClass: "class" },
                            [
                              _c(
                                "div",
                                { staticClass: "class-name" },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.checked,
                                        callback: function ($$v) {
                                          _vm.checked = $$v
                                        },
                                        expression: "checked",
                                      },
                                    },
                                    [_vm._v(_vm._s(cls.name))]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "item" },
                                _vm._l(cls.detailList, function (mod) {
                                  return _c(
                                    "div",
                                    { key: mod.id, staticClass: "items" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.checked,
                                            callback: function ($$v) {
                                              _vm.checked = $$v
                                            },
                                            expression: "checked",
                                          },
                                        },
                                        [_vm._v(_vm._s(mod.name))]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "detail-data mod" },
        [
          _c("en-title-card", { attrs: { name: "方案库数据" } }, [
            _c(
              "div",
              { attrs: { slot: "right" }, slot: "right" },
              [
                _c(
                  "en-button",
                  {
                    attrs: {
                      icon: "rizhi",
                      text: "",
                      iconSize: "16",
                      color: "#333333",
                      iconColor: "#A9B5C6",
                    },
                    on: { click: _vm.compareRecord },
                  },
                  [_vm._v(" 对比日志 ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "data-list" }, [
            _c("div", { staticClass: "data-tit" }, [_vm._v("基础数据")]),
            _c(
              "div",
              { staticClass: "data-cent" },
              _vm._l(_vm.resMap.baseDataList, function (item) {
                return _c("en-card", {
                  key: item.id,
                  staticClass: "data-mod",
                  attrs: {
                    type: "normal",
                    title: item.name,
                    "sub-title": _vm.getSubTitle(item),
                    "icon-name": "mingxizhang-xianxing",
                    "icon-color": "#ffffff",
                    "icon-bg": "#3e90fe",
                    "edit-able": "false",
                    disabled: "false",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.popBaseField(item)
                    },
                  },
                })
              }),
              1
            ),
          ]),
          _c("div", { staticClass: "data-list" }, [
            _c("div", { staticClass: "data-tit" }, [_vm._v("数据字典")]),
            _c(
              "div",
              { staticClass: "data-cent" },
              _vm._l(_vm.resMap.dictionaryList, function (item) {
                return _c("en-card", {
                  key: item.id,
                  staticClass: "data-mod",
                  attrs: {
                    type: "normal",
                    title: item.name,
                    "sub-title": _vm.getSubTitle(item),
                    "icon-name": "mingxizhang-xianxing",
                    "icon-color": "#ffffff",
                    "icon-bg": "#3e90fe",
                    "edit-able": "false",
                    disabled: "false",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.popBaseField(item)
                    },
                  },
                })
              }),
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "detail-desc mod" },
        [
          _c("en-title-card", { attrs: { name: "方案库数据" } }),
          _c("div", { staticClass: "desc-list" }, [
            _c("div", { staticClass: "desc-tit" }, [
              _c("div", { staticClass: "version-wrap" }, [
                _c("div", { staticClass: "version" }, [
                  _vm._v("版本：" + _vm._s(_vm.resMap.version)),
                ]),
                _c("div", { staticClass: "version-date" }, [
                  _vm._v(_vm._s(_vm.resMap.versionTime)),
                ]),
              ]),
              _c("div", { staticClass: "describe" }, [
                _vm._v(_vm._s(_vm.resMap.contentDesc)),
              ]),
            ]),
            _c("div", {
              staticClass: "desc-content",
              domProps: { innerHTML: _vm._s(_vm.resMap.descInfo) },
            }),
          ]),
        ],
        1
      ),
      _c("versionRecord", {
        ref: "verRecord",
        attrs: { schemeId: _vm.query.eschemeId },
      }),
      _c("compareLog", {
        ref: "comLog",
        attrs: {
          schemeId: _vm.query.eschemeId,
          schemeVersion: _vm.query.eversion,
        },
      }),
      _c("installConfirm", {
        ref: "installCfm",
        on: { confirmSave: _vm.confirmSave },
      }),
      _c("installAuth", { ref: "installAut", on: { authSave: _vm.authSave } }),
      _c("baseField", {
        ref: "bField",
        attrs: {
          schemeId: _vm.query.eschemeId,
          schemeVersion: _vm.query.eversion,
          fieldAttr: _vm.fieldAttr,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }